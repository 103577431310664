import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FAQContainer, FAQs } from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Local Sports Aviation Clubs - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Join your local paragliding, hang gliding, microlighting or
        multidisciplinary club."
      />
    </Helmet>

    <HomeHeader slant={false}>
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Local Clubs</h2>
        <Text size="large">
          Join your local paragliding, hang gliding, microlighting or
          multidisciplinary club.
        </Text>
      </div>
    </HomeHeader>



    <FAQContainer style={{ marginTop: -65 }}>
      <FAQs>
        <h2>Our Local Clubs</h2>
        <p>
          Our QLD clubs manage all flights operations on the respective sites and
          regularly put in a lot of effort to foster the sport in QLD, for
          example:
        </p>
        <ul>
          <li>Liaise with landowners, Airservices, QLD Parks and other stakeholders to maintain and/or acquire flying sites</li>
          <li>Clearance and maintenance of flying sites (e.g., by purchasing mowers, chain saws, organising working bees, etc.)</li>
          <li>Purchase and maintenance of windstations</li>
          <li>Supervision of novice pilots</li>
          <li>Provide site briefings for visiting pilots</li>
          <li>Organise VHF and airspace workshops</li>
          <li>Organise reserve repacks</li>
          <li>Engage in SO training</li>
          <li>Organise fly-ins, competitions and/or skills workshops</li>
        </ul>
        <Box mb={3} />
        <h4 style={{ color: '#c12422' }}>Sunshine Coast Free Flyers (SCFF)</h4>
        <p>
          The Sunshine Coast Free Flyers (SCFF) fosters free flying of paragliders
          and hang gliders at flying sites around the Sunshine Coast sites, at a
          range of costal and hinterland sites from Gympie and Rainbow Beach
          down to Woody Point in Redcliffe Peninsula.
        </p>
        <a href="https://freeflyers.com.au/">
          https://freeflyers.com.au/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Canungra Hang Gliding Club</h4>
        <p>
          The CHCG is a club managing flying operations for hang gliders and
          paragliders in the Canungra region located in the Gold Coast hinterland,
          and administer popular sites such as Beechmont and Mt Tamborine (among
          others).
        </p>
        <a href="https://www.flycanungra.com.au">
          https://www.flycanungra.com.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Wicked Wings - Toowoomba and District Para/Hang Gliding Club</h4>
        <p>
          The Wicked Wings Club is an active group of recreational pilots from
          paragliding and hang gliding aviation disciplines. The club administers
          sites around Gatton and Toowoomba and also caters to the flying sites
          at Killarney.
        </p>
        <a href="http://www.wickedwings.com.au">
          http://www.wickedwings.com.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Surf & Turf Flyers</h4>
        <p>
          We are an inclusive sports aviation club who works to make flying an
          enjoyable experience for all our members.
        </p>
        <h4 style={{ color: '#c12422' }}>Dalby Hang Gliding Club</h4>
        <p>
          The Dalby Hang Gliding Club operates from the Dalby Aerodrome, a small
          airport located 1.2 km north of the town of Dalby. Also the home of
          one of Australia's leading hang gliding competitions, The Dalby Big
          Air.
        </p>
      </FAQs>
    </FAQContainer>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Complete the enquiry form and contact us today to find out how we can
        help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query ClubsQuery {
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wing: file(relativePath: { eq: "paraglide-club.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
